.wrapper {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.link {
  --text-gray: #535252;
  --text-black: #000;

  all: initial;

  font-family:
    Inter, Roboto, 'Helvetica Neue', 'Arial Nova', 'Nimbus Sans', Arial,
    sans-serif;

  font-size: 0.813rem;

  line-height: 1.4;

  font-weight: 400;

  cursor: pointer;
  color: var(--text-gray);
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}
.link:hover {
  color: var(--text-black);
}
.link svg {
  margin-left: -0.25rem;
  height: 1.25rem;
  width: 1.25rem;
  stroke-width: 0.07rem;
}
