.toc {
  --block-text: #535252;
  --block-text-active: #000;
  --block-border: #e8e8e8;
  --block-border-active: #000;
  all: initial;
  font-family:
    Inter, Roboto, 'Helvetica Neue', 'Arial Nova', 'Nimbus Sans', Arial,
    sans-serif;
  font-size: 0.813rem;
  line-height: 1.4;
  font-weight: 400;
  color: var(--block-text);
}

.toc ul {
  margin: 0 !important;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  gap: 0;
}

.toc ul li {
  margin: 0;
  padding: 0;
}

.toc ul li::before {
  content: none;
}

.tocItem__header {
  display: flex;
  align-items: center;
  gap: 0.375rem;
  font-size: 0.813rem;
  font-weight: 400;
  margin: 0;
  margin-bottom: 1rem;
  padding: 0;
  line-height: 1.4;
}

.tocItem__header svg {
  height: 1.25rem;
  width: 1.25rem;
  stroke: currentcolor;
}

.toc a,
.toc li[data-level='h2'] a {
  margin: 0;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  border-left: 1px solid transparent;
}
.toc ul a,
.toc li[data-level='h2'] a {
  padding-left: 1.25rem;
}
.toc ul ul a,
.toc li[data-level='h3'] a {
  padding-left: 2rem;
}

.toc a {
  display: block;
  font-weight: 400;

  --tw-text-opacity: 1;
  text-decoration-line: none;

  color: var(--block-text);
  border-color: var(--block-border);
  transition-property:
    color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 100ms;
}

.toc a:hover {
  color: var(--block-text-active);
  border-color: var(--block-border-active);
  text-decoration-line: none;
}

.toc a[data-active='true'] {
  color: var(--block-text-active);
  border-color: var(--block-border-active);
}
