.breadcrumbs {
  --text-gray: #535252;
  --bg-active: rgba(0, 0, 0, 0.03); /* #f8f8f8 */
  font-family:
    Inter, Roboto, 'Helvetica Neue', 'Arial Nova', 'Nimbus Sans', Arial,
    sans-serif;
  font-size: 0.875rem;
  line-height: 1.7;
  font-weight: 400;
  margin-block: 0;
  margin-left: -0.375rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.125rem;
}
.breadcrumbs a {
  flex-shrink: 0;
  color: var(--text-gray);
}
.breadcrumbs a:hover {
  background-color: var(--bg-active);
  color: var(--text-gray) !important;
}
.breadcrumb {
  padding: 0.25rem 0.375rem;
  border-radius: 0.125rem !important;
  font-size: 0.875rem;
  line-height: 1.4;
  color: currentcolor;
}

.breadcrumbIcon:last-child {
  display: none;
}
