.sidebarSearch {
  --block-border: #e8e8e8;
  position: relative;
}

.sidebarSearch_shortcuts {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.125rem;
  font-family:
    Inter, Roboto, 'Helvetica Neue', 'Arial Nova', 'Nimbus Sans', Arial,
    sans-serif;
  font-size: 0.813rem;
  line-height: 1.4;
  font-weight: 400;

  --tw-text-opacity: 1;
  color: rgb(105 105 105 / var(--tw-text-opacity));
  pointer-events: none;
}
.sidebarSearch_shortcuts kbd {
  font-family:
    Inter, Roboto, 'Helvetica Neue', 'Arial Nova', 'Nimbus Sans', Arial,
    sans-serif;
  font-size: 0.813rem;
  font-weight: 400;

  --tw-text-opacity: 1;
  color: rgb(105 105 105 / var(--tw-text-opacity));
  border-radius: 0.25rem;
  border-width: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: var(--block-border);
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}
.sidebarSearch_shortcuts kbd svg {
  stroke: currentcolor;
  width: 18px;
  height: 18px;
  stroke-width: 1.5px;
}

.body :global .ais-Hits-list {
  all: initial;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-family:
    Inter, Roboto, 'Helvetica Neue', 'Arial Nova', 'Nimbus Sans', Arial,
    sans-serif;
  font-size: 0.875rem;
  line-height: 1.7;
  font-weight: 400;
}
